<template>
    <div class="platon-dialog page page-view">
        <div v-if="isModal && pageInfo" class="pmodal-title d-flex align-items-center modal-form-header">
            <div class="flex-grow-1">
                <slot name="title">{{ pageInfo.title }}</slot>
            </div>
            <div @click="$emit('close')" class="cursor-pointer modal-close"><i class="fa fa-times-circle"></i></div>
        </div>

        <page-renderer ref="page" :route="localRoute" :chimera-options="chimeraPageLoadOptions" />
    </div>
</template>

<script>
import PageRenderer from "@Platon/components/pages/PageRenderer.vue"
import TitleMixin from "@Platon/mixins/TitleMixin"
import ModalCloseOnEscMixin from "@Platon/mixins/ModalCloseOnEscMixin"
import ChimeraErrorsView from "@Platon/components/misc/ChimeraErrorsView.vue"
import PublicPageMixin from "@Platon/mixins/PublicPageMixin"
import encodedUrlBuilder from "@Platon/core/http/EncodedUrlBuilder"
import { isObjDiff } from "@Platon/core/helpers"
import ModalRouteMixin from "@Platon/mixins/ModalRouteMixin"
import PluginEvents from "@Platon/core/plugins/PluginEvents"

export default {
    name: "PlatonPageView",
    components: { PageRenderer, ChimeraErrorsView },

    mixins: [TitleMixin, ModalCloseOnEscMixin, PublicPageMixin, ModalRouteMixin],

    data() {
        return {
            pageInfo: null,
            reloadOnFilterChange: false
        }
    },

    provide() {
        return {
            $pageView: this
        }
    },

    computed: {
        isModal() {
            return !!this.route
        },

        chimeraPageLoadOptions() {
            return {
                url: this.addPublicRoutePrefix(
                    encodedUrlBuilder(`pages/${this.localRoute.params.name}`, this.localRoute.query)
                ),

                on: {
                    success: ({ data }) => {
                        this.pageInfo = data
                        this.setTitle(data.title)
                        this.$plugins.triggerEvent(PluginEvents.OnPageOpened, {
                            data,
                            queryParams: this.$route.query
                        })
                    }
                }
            }
        }
    },

    watch: {
        $route(current, old) {
            if (this.reloadOnFilterChange && isObjDiff(current, old)) {
                if (this.$refs.page) {
                    this.$refs.page.reload()
                }
            }
        }
    }
}
</script>

<style></style>
